<template>
  <div
    id="offerPopup"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="offerPopup"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            Make Offer
          </h5>
          <button
            ref="Close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          />
        </div>
        <form @submit.prevent="offerNFTPrice">
          <div class="modal-body">
            <div class="mb-3">
              <label
                for="maticSalePrice"
                class="form-label"
              >Price in KRO</label>
              <input
                id="maticSalePrice"
                v-model="maticSalePrice"
                type="number"
                class="form-control"
                placeholder="Enter price in Matic"
                required
                :step="selectedCurrencyOption.currency === 'ETH' ? '0.01' : '1'"
                :min="selectedCurrencyOption.currency === 'ETH' ? '0.01' : '1'"
              />
            </div>
            <div
              v-if="showPriceAlert"
              class="alert alert-danger mt-3"
            >
              Price cannot be less than 0.
            </div>
            <!-- <div style="display: flex; justify-content: space-evenly;"> 
              <select v-model="selectedCurrencyOption" class="form-select">
                <option v-for="option in currencyOptions" :key="option" :value="option">
                  {{ option.currency }}
                </option>
              </select>
            </div> -->
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="isLoading"
              style="min-width: 120px;"
            >
              <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin" /></span>
              <span v-else>Offer</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from '@/stores/index.ts';
import { ethers } from 'ethers';
import { createRaribleSdk } from "@rarible/sdk";
import { useToast } from 'vue-toast-notification';
import { getContractAddressByItemId } from '@/utils/contractUtils.js';
import { toCurrencyId, toItemId, toOrderId ,toCollectionId} from "@rarible/types"
import { getAllCurrency } from '@/utils/exchangeUtils.js';

import NFTMarketplaceOfferABI from '@/contracts/NFTMarketplaceOfferABI.json';
import FighterERC721ABI from '@/contracts/FighterERC721ABI.json';
import Web3 from 'web3';
import ERC20ABI from '@/contracts/ERC20ABI.json';

export default {
  props: {
    showModal: Boolean,
    product: {},
    type: "fighter",
  },
  data() {
    return {
      allCurrency: getAllCurrency(),
      maticSalePrice: 0,
      isLoading: false,
      showPriceAlert: false,
      selectedCurrencyOption: '',
      currencyOptions: [],
    };
  },
  mounted(){
    console.log(this.type);
    // if(this.type === "fighter"){
    //   // this.currencyOptions = ['JAB', 'ETH'];
    //   this.currencyOptions = this.allCurrency;
    // }
    // else{
    //   // this.currencyOptions = ['ETH'];
    //   this.currencyOptions = [this.allCurrency[1]];
    // }
    this.currencyOptions = this.allCurrency;
    this.selectedCurrencyOption = this.currencyOptions.find(f=>f.currency === 'KRO');
    console.log(this.selectedCurrencyOption);
  },
  methods: {
    closeModal() {
      this.$refs.Close.click();
    },
    async offerNFTPrice() {
      try {
        const store = useStore();
        const accountAddress = store.getWalletAddress;

        if (this.maticSalePrice <= 0) {
          this.showPriceAlert = true;
          return;
        } else {
          this.showPriceAlert = false;
        }

        this.isLoading = true;

        // const price = parseFloat(this.maticSalePrice);

        // if(price > parseFloat(store.getWalletBalance.jab)){
        //   this.isLoading = false;
        //   useToast().open({
        //     message: 'Error: JAB is not enough',
        //     position: 'top-right',
        //     type: 'error',
        //   });
        //   this.closeModal();
        //   return;
        // }

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = await provider.getSigner();
        // const apiKey = process.env.VITE_RARIBLE_API_KEY; 
        // const raribleSdk = createRaribleSdk(signer, "prod", { apiKey });

        // Define the NFT asset details
        let tokenAddress = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;
        let tokenId = 0;

        if (this.type === 'item') {
          tokenAddress = getContractAddressByItemId(parseInt(this.product.itemId));
          if(this.product.token_id >= 20000){
            tokenAddress = process.env.VITE_ARTIFACT_CONTRACT_ADDRESS;
          }
          else if(this.product.token_id >= 10000){
            tokenAddress = process.env.VITE_STONE_CONTRACT_ADDRESS;
          }
          tokenId = this.product.token_id;
        } else {
          tokenId = this.product.properties.token_id;
        }

        // const request = {
        //   itemId: toItemId(`KROMA:${tokenAddress}:${tokenId}`),
        //   amount: 1, // amount of NFTs to sell
        //   price: price, // Use the price from the input
        //   currency: toCurrencyId(`KROMA:${process.env.VITE_JAB_CONTRACT_ADDRESS}`), // Ensure the environment variable is correct
        // };

        // console.log("request:", request);

        // const orderId = await raribleSdk.order.bid(request);

        // console.log("Listing successful:", orderId);

        const marketplaceAddress = process.env.VITE_MARKETPLACE_OFFER_ADDRESS;

        const marketplaceContract = new ethers.Contract(marketplaceAddress, NFTMarketplaceOfferABI, signer);

        const currencyAddress = this.selectedCurrencyOption.contract;

        // const orderId = await raribleSdk.order.sell(sellRequest);
        const tokenContract = new ethers.Contract(
                    currencyAddress,
                    ERC20ABI,
                    signer
                );

        const price = Number(this.maticSalePrice);
        console.log("Price:", price);
                const decimals = await tokenContract.decimals(); // Get token decimals
                console.log("Token decimals:", decimals);
        // console.log("Listing successful:", orderId);

        console.log("Parameters for listNFT:");
        console.log("NFT Contract Address:", process.env.VITE_FIGHTER_CONTRACT_ADDRESS);
        console.log("Token ID:", tokenId);
        const formattedPrice = ethers.utils.parseUnits(parseFloat(this.maticSalePrice).toString(), 18);
        console.log("Price (as string):", formattedPrice);
        console.log("Currency Address:", currencyAddress);

        // const nftContract = new ethers.Contract(
        //     process.env.VITE_FIGHTER_CONTRACT_ADDRESS,
        //     FighterERC721ABI, 
        //     signer 
        // );

        // Check if the token is already approved
        const priceInWei = ethers.BigNumber.from(price).mul(ethers.BigNumber.from(10).pow(decimals));

        console.log(`Price in Wei: ${priceInWei.toString()}`);

        const priceWithDecimals = ethers.utils.parseUnits(price.toString(), decimals); // Scale price
        console.log("Required price (scaled):", priceWithDecimals.toString());
        
        // Check Allowance
        const spender = process.env.VITE_MARKETPLACE_OFFER_ADDRESS;
        const allowance = await tokenContract.allowance(await signer.getAddress(), spender);
        console.log("Allowance for spender:", spender, allowance.toString());


        console.log("Current allowance:", allowance.toString());
        console.log("Required price:", priceWithDecimals.toString());
        if (ethers.BigNumber.from(allowance).lt(priceWithDecimals)) {
          console.log("Current allowance:", allowance.toString());
            console.log("Approving tokens...");
            const approveTx = await tokenContract.approve(process.env.VITE_MARKETPLACE_OFFER_ADDRESS, priceInWei, {
                gasLimit: 60000, // Adjust as needed
                gasPrice: ethers.utils.parseUnits('0.003', 'gwei') // Adjust gas price
            });
            await approveTx.wait();
        }

        const duration_day = 7;
        const duration_time = duration_day * 24 * 60 * 60;
        console.log("Current allowance:", duration_time);

        // Call the listNFT function
        const tx = await marketplaceContract.makeOffer(
            process.env.VITE_FIGHTER_CONTRACT_ADDRESS,
            tokenId,
            formattedPrice,
            currencyAddress,
            duration_time,
                    {
                      gasLimit: 6000000, // Gas limit
                        gasPrice: Web3.utils.toWei('0.003', 'gwei')
                    }
        );

        // Wait for the transaction to be mined
        const receipt = await tx.wait();

        console.log("NFT listed successfully:", receipt.transactionHash);

        this.isLoading = false;
        useToast().open({
          message: 'Success offer.',
          position: 'top-right',
          type: 'success',
        });
        if(this.type === 'fighter'){
          this.$emit('fetchEvent');
        }
        else{
          this.$emit('fetchEvent', tokenAddress);
        }
        this.closeModal();
      } catch (error) {
        console.error('Error listing NFT on Rarible:', error);
        this.isLoading = false;
        useToast().open({
          message: 'Error: ' + error.message,
          position: 'top-right',
          type: 'error',
        });
        this.closeModal();
      }
    },
  }
};
</script>
