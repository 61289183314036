<template>
  <div
    id="withdrawPopup"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="withdrawPopup"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            Withdraw
          </h5>
          <button
            ref="Close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          />
        </div>
                
        <form @submit.prevent="withdraw">
          <div class="modal-body">
            <div
              class="row"
              style="height: 70px; margin-bottom: 10px;"
            >
              <div class="col-3 item-center">
                In Game :
              </div>
              <div
                class="col-9 item-center"
                style="height: 100%; display: grid; grid-auto-rows: auto; padding: 0 1rem 0 0;"
              >
                <div
                  class="no-space-around"
                  style="border: 1px solid #495057; border-radius: 5px; width: 100%; padding: 0.5rem 0.75rem; display: flex; align-items: center; gap: 15px;"
                >
                  <div
                    class=""
                    style="width: auto;"
                  >
                    Balance : {{ inGameWallet.jabBalance }} JAB
                  </div>
                  <div
                    class="col-1 no-space-around"
                    style="display: flex; align-items: center;"
                  >
                    <img
                      data-v-1292d68d=""
                      src="~@/assets/icons/icon-jab.png"
                      alt="Owner Icon"
                      class="stat-icon"
                      height="20px"
                    >
                  </div>
                </div>
              </div>
            </div>
            <div 
              class="row"
              style="margin-bottom: 10px;">
              <div
                class="col-3"
                style="display: flex; align-items: center;"
              >
                <label
                  for="eventTotal"
                  class="form-label no-space-around"
                >
                  <span> Withdraw : </span>
                </label>
              </div>
              <div
                class="col-9"
                style="padding: 0 1rem 0 0;"
              >
                <div class="">
                  <input
                    id="withdrawAmount"
                    v-model="withdrawAmount"
                    type="number"
                    min="0"
                    class="form-control"
                    placeholder="Enter Amount"
                    required
                  >
                </div>
                <div
                  v-if="showErrorAlert"
                  class="alert alert-danger mt-3"
                >
                  Invalid Total format.
                </div>
              </div>
            </div>
            <div 
              class="row">
              <div
                class="col-3"
                style="display: flex; align-items: center;"
              >
                <label
                  for="eventTotal"
                  class="form-label no-space-around"
                >
                  <span> Fee : </span>
                </label>
              </div>
              <div
                class="col-9"
                style="padding: 0 1rem 0 0;"
              >
                <div class="d-flex justify-content-between">
                  <div
                    class="d-flex justify-content-center align-items-center">
                    <img
                      :src="require(`@/assets/currency/KRO.png`)"
                      alt="currency icon"
                      style="width: 20px; height: auto; margin-right: 10px"
                    >
                    30 $KRO
                  </div>
                  <div class="d-flex justify-content-center align-items-center" style="gap: 10px; color: #B7B7B7;">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#B7B7B7">
                      <path d="M200-200v-560 560Zm0 80q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v100h-80v-100H200v560h560v-100h80v100q0 33-23.5 56.5T760-120H200Zm320-160q-33 0-56.5-23.5T440-360v-240q0-33 23.5-56.5T520-680h280q33 0 56.5 23.5T880-600v240q0 33-23.5 56.5T800-280H520Zm280-80v-240H520v240h280Zm-160-60q25 0 42.5-17.5T700-480q0-25-17.5-42.5T640-540q-25 0-42.5 17.5T580-480q0 25 17.5 42.5T640-420Z"/>
                    </svg>
                    {{ kroBalance }} $KRO
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" :class="{'justify-content-between': !isWithdrawable }" style="">
            <div v-if="!isWithdrawable" style="color: red; font-size: 14px;">
              You don't have enough KRO token to proceed
            </div>
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="isLoading || !isWithdrawable"
              style="min-width: 120px;"
            >
              <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin" /></span>
              <span v-else> Withdraw </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <TransactionPendingPopup :message="message" />
</template>
  
<script>
import axios from 'axios';
import Web3 from 'web3';
import { useStore } from '@/stores/index.ts';
import { ethers } from 'ethers';
import { OpenSeaSDK, Chain, Seaport } from "opensea-js";
import { ref,computed } from 'vue';
import {useToast} from 'vue-toast-notification';
import PortalContractABI from '@/contracts/PortalContractABI.json';
import { getSignature } from '@/utils/web3Utils';

import TransactionPendingPopup from "@/components/popup/TransactionPendingPopup.vue";
import ERC20ABI from '@/contracts/ERC20ABI.json';

export default {
    components: {
        TransactionPendingPopup
    },
    props: {
        showModal: Boolean,
        inGameWallet: {
            type: Object,
            required: true
        }
    },
    setup() {
        const store = useStore()
    },
    data() {
        return {
            isLoading: false,
            showErrorAlert: false,
            withdrawAmount: 0,
            kroBalance: 0,
            message: {},
            isWithdrawable: false
        };
    },
    computed: {
        jabBalance: () => useStore().wallet.jab.toString(),
    },
    mounted(){
      this.fetchWalletBalance();
    },
    methods: {
      closeModal() {
          console.log('Closing modal'); // Add this line
          this.$refs.Close.click();
      },
      async withdraw() {
          var $ = jQuery.noConflict();

          this.isLoading = true;
          this.closeModal();
          this.message = {
                  title: 'Withdraw pending',
                  image: '../images/loading/transaction-loading.gif',
                  text: "Go to your wallet <br> You'll be asked to approve this transfer from your wallet."
              }
          $(document).ready(function() {
              $('#transactionPendingPopup').modal('show');
          });

          try {

            const { signature, challengeMessage, address } = await getSignature();
              const accountAddress = useStore().getWalletAddress;
              console.log("accountAddress" + accountAddress);

              /*const requestBody = {
                  amount: this.withdrawAmount.toString(),
                  token: "JAB",
                  signature,
                  challengeMessage,
                  address,
                  wallet_address: address
              };
              console.log('requestBody:', requestBody);*/


              //const apiUrl = `https://reward-portal.kryptofighters.io/api/protect/testnet/withdraw-token`;
              //console.log('apiUrl:', apiUrl);

              //const response = await axios.post(apiUrl, requestBody);
              //console.log('response:', response.data);

              const contractAddress = process.env.VITE_PORTAL_CONTRACT_ADDRESS;
            
              const provider = new ethers.providers.Web3Provider(window.ethereum);
              const signer = await provider.getSigner(); // Use this if you have a signer (e.g., MetaMask)

              const currencyAddress = process.env.VITE_KRO_CONTRACT_ADDRESS;
              const tokenContract = new ethers.Contract(
                  currencyAddress,
                  ERC20ABI,
                  signer
              );
              const allowance = await tokenContract.allowance(await signer.getAddress(), contractAddress);
              console.log("Allowance for spender:", contractAddress, allowance.toString());


              const feeAmount = 30;
              const decimals = await tokenContract.decimals(); // Get token decimals
              console.log("Token decimals:", decimals);
              const priceInWei = ethers.BigNumber.from(feeAmount).mul(ethers.BigNumber.from(10).pow(decimals));
              console.log(`Price in Wei: ${priceInWei.toString()}`);
              const priceWithDecimals = ethers.utils.parseUnits(feeAmount.toString(), decimals); // Scale price
              console.log("Required price (scaled):", priceWithDecimals.toString());
              console.log("Current allowance:", allowance.toString());
              console.log("Required price:", priceWithDecimals.toString());
              if (ethers.BigNumber.from(allowance).lt(priceWithDecimals)) {
                console.log("Current allowance:", allowance.toString());
                  console.log("Approving tokens...");
                  const approveTx = await tokenContract.approve(contractAddress, priceInWei, {
                      gasLimit: 60000, // Adjust as needed
                      gasPrice: ethers.utils.parseUnits('0.003', 'gwei') // Adjust gas price
                  });
                  await approveTx.wait();
              }
              
              // Create an ethers.js contract instance
              const contract = new ethers.Contract(contractAddress, PortalContractABI, signer);

              const withdrawAmountInEther = ethers.utils.parseEther(this.withdrawAmount.toString());
              const tx = await contract.withdraw( withdrawAmountInEther);
              this.message.text = "Your transaction is pending. please do not close this page.";
              await tx.wait();

              useToast().open({
                      message: 'Withdraw completed.',
                      position: 'top-right',
                      type: 'success',
                  });

              await this.fetchWalletBalance();

              $(document).ready(function() {
                  $('#transactionPendingPopup').modal('hide');
              });
              
          } catch (error) {
              console.error('Error withdraw:', error);
              const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
              useToast().open({
                      message: 'Error: ' + errorMessage,
                      position: 'top-right',
                      type: 'error',
                  });
              
              $(document).ready(function() {
                  $('#transactionPendingPopup').modal('hide');
              });
              this.closeModal();
          }
      },
      async fetchWalletBalance() {
        const contractAbi = [
            {
                constant: true,
                inputs: [{ name: '_owner', type: 'address' }],
                name: 'balanceOf',
                outputs: [{ name: 'balance', type: 'uint256' }],
                type: 'function',
            },
        ];
        this.isWithdrawable = false;
        try {
            await window.ethereum.request({ method: 'eth_requestAccounts' });

            const networkId = await window.ethereum.request({ method: 'net_version' });
                        
            if (networkId === process.env.VITE_NETWORK_ID || networkId === '80001') {
                const accounts = await window.ethereum.request({ method: 'eth_accounts' });
                const account = accounts[0];

                const web3 = new Web3(window.ethereum);

                const balanceWei = await web3.eth.getBalance(account);
                const balanceEther = web3.utils.fromWei(balanceWei, 'ether');

                console.log(`Matic Balance for ${account}: ${balanceEther} MATIC`);

                const kroTokenContract = new web3.eth.Contract(contractAbi, process.env.VITE_KRO_CONTRACT_ADDRESS);
                const kroBalanceWei = await kroTokenContract.methods.balanceOf(account).call();
                const kroBalanceWith2DecimalPlaces = parseFloat(web3.utils.fromWei(kroBalanceWei, 'ether')).toFixed(0);
                console.log(`KRO Balance ${kroBalanceWith2DecimalPlaces} KRO`);
                this.kroBalance = kroBalanceWith2DecimalPlaces;

                if(this.kroBalance >= 30){
                  this.isWithdrawable = true;
                }
            } else {
                console.error('Please switch to the Matic network in MetaMask');
            }
        } catch (error) {
            console.error('Error fetching Matic balance:', error);
        }
      }
    }
};
</script>

<style scoped>
.no-space-around {
    padding: 0;
    margin: 0;
}

.item-center {
    align-self: center;
}
</style>
  
