<template>
  <div class="page-content content-wrap"  :style="{ display: isLoading ? 'none' : 'block' }">
    <div class="main-content">
      <div class="d-flex align-items-center justify-content-between default-top-space head-title">
        <div>
          <h1>Scholarship Program</h1>
          <!-- <p>Description</p> -->
        </div>
      </div>
      <div class="d-flex" style="flex-direction: column; gap: 20px;">
        <div class="d-flex justify-content-end">
          <button 
            class="btn btn-primary" 
            data-bs-toggle="modal" 
            data-bs-target="#editScholarshipPopup"
            @click="setSelectedIndex(-1)">
              Create Scholar
          </button>
        </div>
        <div v-if="totalTeam.length < 1" class="" style="width: 100%; background-color: #181822; border-radius: 5px; padding: 50px 10px;">
          <img src="~@/assets/no-data.png" alt="no data"
                style="display: block; margin-left: auto; margin-right: auto; width: 15%; min-width: 160px;">
        </div>
        <div v-else class="d-flex" style="flex-direction: column; gap: 20px;">
          <div v-for="(team, index) in totalTeam" style="width: 100%; background-color:var(    --primary-background-color) ; border-radius: 8px; padding: 10px; display: flex; flex-direction: column; gap: 10px;">
            <div style="padding: 5px 10px;">
              <div class="detail-row">
                <div class="d-flex" style="gap: 15px;">
                  <div class="crop-container crop-container-profile" style="margin-right: 20px; margin-left: 20px;">
                    <img :src="require(`@/assets/icons/icon-scholar-${team.type ? 'private' : 'public'}.png`)" 
                      @load="onImageLoad" 
                      @error="onImageError" 
                      alt="profile">
                  </div>
                  <div class="detail-column" style="">
                    <div class="text-section" style="font-size: 20px;">{{ team.name }}</div>
                    <div>
                      <span class="text-section">Pack Code :</span> {{ team.code }}
                    </div>
                    <div>
                      Type:  <span class="text-section" :class="team.type ? 'text-private' : 'text-public'">
                        {{ team.type ? 'Private' : 'Public' }}
                      </span>
                    </div>
                    <div>
                      <span class="text-section">Total Fighters:</span> {{ team.fighters? team.fighters.length:0 }}
                      
                    </div>
                  </div>
                </div>
                <div class="detail-column" style="">
                  <div class="d-flex text-center" style="gap: 10px;">
                    
                    <!-- <button class="btn btn-mini btn-custom d-flex justify-content-center align-items-center" style="font-size: 24px;">+</button> -->
                  </div>
                  <div class="detail-column" style="">
                    
                    <div>
                      <span class="text-section">Energy :</span> {{ team.energy }}/100
                    </div>
                    <div>
                      <span class="text-section">Current Player :</span> {{ team.current_player }}
                    </div>
                    <!-- <button class="btn btn-mini btn-custom d-flex justify-content-center align-items-center" style="">
                      <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="white">
                        <path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z"/>
                      </svg>
                    </button> -->
                  </div>
                </div>
                <div class="detail-column" style="">
                  <div>
                      <span class="text-section">Share Rate :</span> {{ team.share_rate }}%
                    </div>
                  <div>
                    <span class="text-section">Total Jab Rewards:</span> {{ team.jab }}
                  </div>
                  <button class="btn btn-custom" disabled="true" style="margin-top: 10px;">Claim Rewards</button>
                </div>
                <div class="btn-edit">
                  <button 
                    class="btn btn-secondary d-flex justify-content-center align-items-center" 
                    style="" 
                    id="editBtn" 
                    data-bs-toggle="modal" 
                    data-bs-target="#editScholarshipPopup"
                    @click="setSelectedIndex(index)">
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="white">
                      <path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z"/>
                    </svg>
                    <span class="text-section"> Edit</span>
                  </button>
                </div>
                <button 
                  :class="['collapse-trigger-toggle', { 'collapse-trigger-toggle-transform': fightersCollapsed[index].isCollapsed }]" 
                  data-bs-toggle="collapse" 
                  :data-bs-target="'#fightersContent' + index"
                  aria-expanded="false" 
                  :aria-controls="'#fightersContent' + index"
                  @click="toggleCollapse(index)">
                  <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#e8eaed" style="right: 0">
                    <path d="M480-360 280-559.33h400L480-360Z"/>
                  </svg>
                </button>
              </div>
            </div>
            <div class="collapse" :id="'#fightersContent' + index" :class="{ show: fightersCollapsed[index].isCollapsed }" style="width: 100%; background-color: var( --dark-background-color); border-radius: 5px; padding: 15px;">
              <div v-if="fightersCollapsed[index].isLoading" class="fighter-content" style="justify-content: center; height: 60px;">
                <span><i class="fa fa-circle-o-notch fa-spin" /></span>
              </div>
              <div v-else class="fighter-content">
                <div style="width:80%">
                  <div class="d-flex fighter-list-container" style="">
                    <div v-for="(i, index) in team.fighters" class="crop-container-fighter" style="">
                      <img :id='`fighterImg_${team.id}_${i.id}`' :src="i.image?? ''" alt="fighter Image" @load="cropFighterImage(team.id, i.id, i.class_id)">
                    </div>
                  </div>
                </div>
                
                <button 
                  class="btn btn-secondary" 
                  data-bs-toggle="modal" 
                  data-bs-target="#assignFighterPopup"
                  @click="setSelectedIndex(index, false)"
                  >
                  Add Fighters
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isLoading" class="loading-content" style="/*padding: 50px 0px;*/" >
      <div class="loading-bar"></div>
  </div>
  <EditScholarshipPopup 
    :title="selectedIndex === -1 ? 'Add Fighter\'s Pack' : ''" 
    :data="getScholarshipData()" 
    @updateEditData="updateEditData"/>
  <AssignFighterPopup 
    :packData="getScholarshipData()"
    @assignFighter="updateFighters"/>
</template>

<script>
import { useStore } from '@/stores/index.ts';
import axios from 'axios';
import { watch } from 'vue';
import { useToast } from 'vue-toast-notification';
import { getClassImageUrl, getElementImageUrl } from '@/utils/imageUtils.js';

import EditScholarshipPopup from "@/components/popup/EditScholarshipPopup.vue";
import AssignFighterPopup from "@/components/popup/AssignFighterPopup.vue";

export default {
  components: {
    EditScholarshipPopup,
    AssignFighterPopup
  }, 
  data() {
    return {
      isLoading: true,
      isEditPopup: false,
      isAssignPopup: false,
      fightersCollapsed: [],
      // fightersCollapsed: [{index: 0, isCollapsed: true}],
      totalTeam: [],
      // totalTeam: [{name: 'A', code: '0xAAA', current_player: '...', share_rate: 10, enrgy: 10, jab_produce: 10, jab: 10, fighters: null}],
      selectedIndex: 0,
      totalFighters: [],
      loadedTeams: 0
    }
  },
  async mounted() {
    try {
        if(useStore().getWalletAddress!=''){
          await this.fetchFighter();
          await this.fetchPack();
        }else{
          //alert('Please connect your wallet.');
          watch(
          () => useStore().getWalletAddress,
            async (newWalletAddress) => {
              if (newWalletAddress !== '') {
                await this.fetchFighter();
                await this.fetchPack();
              }
            }
          );
        }
    } catch (error){ 
        console.error('Error:', error);
    }
  },
  methods: {
    async fetchPack(){
        try {
            const accountAddress = useStore().getWalletAddress;
            const apiUrl = `https://reward-portal.kryptofighters.io/api/scholar/pack?wallet=${accountAddress}`;

            const response = await axios.get(apiUrl);
            console.log("gen1Response ",response);

            this.totalTeam = response.data.data.map((pack, index)=>{
              const collapse = {index: index, isCollapsed: true, isLoading: false};
              this.fightersCollapsed.push(collapse);
              
              let fighterList = [];

              if (pack.fighters?.nft_fighter_token_ids?.length) {
                fighterList = pack.fighters.nft_fighter_token_ids.map(fighterId => {
                  const getFighter = this.totalFighters.find(f => f.id == fighterId);
                  console.log('getFighter', getFighter);
                  return getFighter || null; // Avoid pushing undefined if no match is found
                }).filter(fighter => fighter !== null); // Filter out null values
              }
              return {
                id: pack._id,
                name: pack.scholar_name?? '', 
                code: pack.team_code?? 'xxxxxx', 
                type: Boolean(pack.team_type?? 0), // 0 public, 1 private
                current_player: '-', 
                share_rate: pack.percent_share?? 0, 
                energy: 100, 
                jab_produce: 0, 
                jab: 0, 
                fighters: fighterList
              };
            });
            console.log(this.totalTeam);

            if(this.totalTeam.length === 0){
              this.isLoading = false;
            }
        } catch (error) {
            console.error('Error fetching data from OpenSea:', error);
        }
    },
    async fetchFighterData(fighters) {
      try {
          for (const fighter of fighters){
              const response = await axios.get(`https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/fighter/${fighter.tokenId}.json`);
              console.log(response);
              var imageUrl = response.data.image;
              imageUrl = imageUrl.replace("image/4x/", "card/no-bg/");
              const _tempData = {
                  id: fighter.tokenId,
                  image: imageUrl,
                  class_id: response.data.properties.class_id,
                  element_id: response.data.properties.element,
                  classImgUrl: getClassImageUrl(response.data.properties.class_id),
                  elementUrl: getElementImageUrl(response.data.properties.element),
                  isSelect: false
              }

              this.totalFighters.push(_tempData);
          }
          console.log('this.totalFighters', this.totalFighters);
          // await this.fetchPack();
      } catch (error) {
          console.error('Error fetching image:', error);
      }
    },
    async fetchFighter(){
      try {
          const accountAddress = useStore().getWalletAddress;
          const collection = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;
          const apiUrl = `https://api.rarible.org/v0.1/ownerships/search`;
          const requestBody = {
              size: 50,
              filter: {
                  blockchains: ['KROMA'],
                  owners: [`ETHEREUM:${accountAddress}`],
                  collections: [`KROMA:${collection}`]
              }
          };
          const response = await axios.post(apiUrl, requestBody, {
              headers: {
                  'accept': 'application/json',
                  'x-api-key': process.env.VITE_RARIBLE_API_KEY
              }
          });
          console.log("gen1Response ",response);

          this.fetchFighterData(response.data.ownerships);
      } catch (error) {
          console.error('Error fetching data from OpenSea:', error);
      }
    },
    async updateFighters(selectedFighters){
      console.log('JSON.stringify(this.totalTeam[this.selectedIndex].fighters)',JSON.stringify(this.totalTeam[this.selectedIndex].fighters));
      console.log('JSON.stringify(selectedFighters)',JSON.stringify(selectedFighters));
      if(JSON.stringify(this.totalTeam[this.selectedIndex].fighters) == JSON.stringify(selectedFighters)){
        return;
      }

      this.fightersCollapsed[this.selectedIndex].isLoading = true;
        try {
            const apiUrl = `https://reward-portal.kryptofighters.io/api/scholar/pack/update_fighter`;

            const fighterList = selectedFighters.map(fighter => {
                return fighter.id;
            });

            const requestBody = {
                team_id: this.totalTeam[this.selectedIndex].id,
                fighter_ids: fighterList
            };

            console.log('requestBody',requestBody);
            const response = await axios.post(apiUrl, requestBody);
            console.log("gen1Response ",response);

            useToast().open({
                message: 'Update fighters successfully.',
                position: 'top-right',
                type: 'success',
            });
            this.assignFighter(selectedFighters);
            this.fightersCollapsed[this.selectedIndex].isLoading = false;
        } catch (error) {
            console.error('Error fetching data from OpenSea:', error);
            const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
            useToast().open({
                message: 'Update error : ' + errorMessage,
                position: 'top-right',
                type: 'error',
            });
            this.fightersCollapsed[this.selectedIndex].isLoading = false;
        }
    },
    toggleCollapse(index) {
      this.fightersCollapsed[index].isCollapsed = !this.fightersCollapsed[index].isCollapsed;
    },
    setSelectedIndex(index, isEdit=true){
      this.selectedIndex = index;
      if(isEdit){
        this.isEditPopup = true;
      }
      else{
        this.isAssignPopup = true;
      }
    },
    getScholarshipData(){
      console.log('this.selectedIndex '+this.selectedIndex);
      if(this.selectedIndex == -1){
        return {
                id: -1,
                name: '', 
                code: '', 
                type: false,
                current_player: '...',
                energy: 100, 
                share_rate: 0, 
                jab_produce: 0, 
                jab: 0, 
                fighters: null 
              };
      }
      else{
        console.log('this.selectedIndex ',this.totalTeam[this.selectedIndex]);
        return this.totalTeam[this.selectedIndex];
      }
    },
    updateEditData(newData){
      this.isEditPopup = false;
      if(this.selectedIndex == -1){
        this.totalTeam.push(newData);
        this.fightersCollapsed.push({index: this.totalTeam.length-1, isCollapsed: true, isLoading: false});
      }
      else{
        this.totalTeam[this.selectedIndex] = newData;
      }
    },
    assignFighter(selectedFighters){
      this.isAssignPopup = false;
      this.totalTeam[this.selectedIndex].fighters = selectedFighters;
      console.log('selectedFighters ', selectedFighters);
    },
    cropFighterImage(team_id, id, classId=0){
      this.$nextTick(() => {
        setTimeout(() => {
          const getElement = document.getElementById(`fighterImg_${team_id}_${id}`);
          var crop = {};
        
          console.log('classId'+classId);
          switch(classId){
            case 1: // ard
            case 4: crop = { top: '-30px', left: '-40px'}; break; // stk
            case 2: crop = { top: '-10px', left: '-35px'}; break; // bdg
            case 7: crop = { top: '-20px', left: '-45px'}; break; // dty
            default: crop = { top: '-35px', left: '-35px'}; break;
          } 
          
          if (getElement && crop) {
            console.log('crop',crop);
            getElement.style.top = crop.top;
            getElement.style.left = crop.left;
            getElement.style.height = crop.height;
          }
        }, 100);
      });
    },
    onImageLoad() {
        this.loadedTeams += 1;
        this.checkAllImagesLoaded();
    },
    onImageError() {
        this.loadedTeams += 1;
        this.checkAllImagesLoaded();
    },
    async checkAllImagesLoaded() {
        console.log(`loaded object: ${this.loadedTeams}/${this.totalTeam.length}`);
        if (this.loadedTeams === this.totalTeam.length) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.isLoading = false;
        }
    }
  }
};
                    
</script>

<style scoped>
.crop-container-profile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.crop-container-fighter {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    background-color: var(--light-background-color);
    flex: 0 0 60px;
    overflow: hidden;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    margin: 0;
    position: relative;
}

.crop-container-fighter img {
    width: auto; 
    height: 300%;
    position: absolute;
}

.crop-container {
    overflow: hidden;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    margin: 0;
    position: relative;
}

.crop-container img {
    width: auto; 
    height: 100%;
}

.text-section {
  font-weight: 500;
}

.btn-mini {
  padding: 0;
  margin: 0;
  height: 30px;
  width: 30px;
}

.btn-custom {
  border: 1px solid white;
}

.text-center {
  align-items: center;
}

.detail-column {
  display: flex;
  flex-direction: column; 
  gap: 3px;
}

.detail-row {
  display: flex;
  justify-content: space-between;
}

.collapse-trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-right: 10px;
}

.collapse-trigger-toggle {
  transition: transform 0.3s;
}

.collapse-trigger-toggle.collapse-trigger-toggle-transform {
  transform: rotate(180deg);
}

.collapse.show {
  display: block;
}

.fighter-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-edit {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fighter-list-container {
  gap: 20px; 
  flex-wrap: nowrap; 
  overflow-x: auto; 
  padding: 5px 0 5px 0;
}

.loading-bar {
    width: 100%;
    height: 2px;
    background-color: var(--primary-background-color);
    position: relative;
    overflow: hidden;
    border-radius: 0px; 
}

.loading-bar:before {
    content: "";   
    position: absolute;
    top: 0;
    left: -100%;             
    width: 100%;            
    height: 100%;           
    background-image: linear-gradient(100deg, #df4bd3 50%, #8322ce 100%);
    animation: loading 2s linear infinite;
}

.loading-content {
    min-height: 650px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    position: relative;
    z-index: 1;
    /* top: -650px; */
    background-color: #100f17;
}

@media (max-width: 768px) {
  .detail-row {
    flex-direction: column;
  }

  .fighter-content {
    flex-direction: column;
    gap: 15px;
  }

  .btn-edit {
    margin-top: 15px;
  }

  .btn-edit button{
    width: 100%;
  }

  .fighter-list-container {
    flex-wrap: wrap;
    overflow-x: unset;
    overflow-y: auto;
    height: 200px;
  }
}

.text-private {
  color: yellow; /* Customize color for Private */
}

.text-public {
  color: cyan; /* Customize color for Public */
}

</style>