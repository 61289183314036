<template>
    <div class="page-content content-wrap">
        <div class="main-content">
 
            <div style="margin-top: 30px; padding: 20px;">
                <img 
                  class=""
                  src="@/assets/elixir_logo.webp"
                  alt="App Icon"
                  style="height: 50px; padding-left: 20px;">
                <div class="d-flex content-blog">
                    <div class="d-flex justify-content-center download-content" style="width: 60%; flex-direction: column; gap: 15px;">
                        <h3 style="font-weight: 400;">
                            Download Elixir desktop launcher and start playing games.
                        </h3>
                        <div class="d-flex" style="flex-direction: column; gap: 15px;">
                            <div class="d-flex align-items-center" style="gap: 10px;">
                                <div class="correct-icon"></div>
                                <span>Install Elixir desktop launcher</span>
                            </div>
                            <div class="d-flex align-items-center" style="gap: 10px;">
                                <div class="correct-icon"></div>
                                <span>Signup and login</span>
                            </div>
                            <div class="d-flex align-items-center" style="gap: 10px;">
                                <div class="correct-icon"></div>
                                <span>Find KryptoFighters and add to library</span>
                            </div>
                            <div class="d-flex align-items-center" style="gap: 10px;">
                                <div class="correct-icon"></div>
                                <span>Tap Play and Enjoy</span>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center align-items-center" style="padding: 15px;">
                            <button
                                class="btn btn-primary btn-install"
                                style="width: 10%; min-width: 240px;"
                                type="button"
                                @click="navigateToUrl"
                            >
                                Download Elixir Launcher
                            </button>
                        </div>
                    </div>
                    <div class="d-flex " style="max-width: 840px;height: auto; padding: 0 50px;">
                        <img 
                            class=""
                            src="@/assets/backgrounds/elixir_ss.png"
                            alt="App Icon"
                            style="width: 100%; height: auto;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>           
export default {
    methods: {
        navigateToUrl() {
            window.open('https://elixir.games/browse/krypto-fighters', '_blank');
        }
    }
};
</script>
  
<style scoped>
    .correct-icon {
        width: 24px;
        height: 24px;
        display: inline-block;
        background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="%2375FB4C"%3E%3Cpath d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z"/%3E%3C/svg%3E') no-repeat center center;
        background-size: contain;
    }

    .btn-install {
        height: 40px;
        width: 100%;
        align-items: center; 
        display: flex;
        justify-content: center;
    }

    @media (max-width: 768px) {
        .content-blog {
            flex-direction: column;
        }

        .download-content {
            width: 100% !important;
        }
    }
</style>